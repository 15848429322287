import styles from './MainPage.module.css';
import carouselStyles from '../CarouselItem.module.css';
import commonStyles from '../Common.module.css';
import Section from '../Section';
import { ReactComponent as Separator } from '../res/separator.svg';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from 'react-router-dom';
import Page from './Page';
import example1 from '../res/examples/example_1.jpg';
import example2 from '../res/examples/example_2.jpg';
import example3 from '../res/examples/example_3.jpg';
import example4 from '../res/examples/example_4.jpg';
import example5 from '../res/examples/example_5.jpg';
import example6 from '../res/examples/example_6.jpg';
import example7 from '../res/examples/example_7.jpg';
import example8 from '../res/examples/example_8.jpg';

export default function App() {
  return (
    <Page>
      <div>
        <p className={styles.vision}>Meine Vision:<br />Saubere und ordentliche Reparaturausführung, einen Beitrag zur Erhaltung ostdeutscher Ingenieursarbeit leisten.</p>
      </div>
      <Separator className={styles.separator} />
      <Section title='Leistungen'>
        <p>Sickenreparatur RFT L7102, L7113, L7114 für BR 25, BR25e, BR26, BR 2725, BR50, BR 3750, Heco Interior Reflex 20 / 30, L1714 für B9282 und weitere DDR-Lautsprecherchassis mit Schaumstoffsicke.</p>
        <p>Weitere Reparaturen, auch an den Typen L7101, L7104 auf Anfrage.</p>
        <p>Die alten Sicken und Klebereste werden restlos entfernt, beschädigte Lackierung der Lautsprecherkörbe matt nachlackiert. Zur Anwendung kommen extra für RFT-Chassis hergestellte Schaumstoffsicken und Kleber. Bei Bedarf werden verblichene Membranen gereinigt und farblich leicht aufgefrischt. Defekte Anschlusslitzen werden erneuert. Eingedrückte oder fehlende Staubschutzkappen werden entfernt, wieder in Form gebracht oder erneuert. Lose Anschlussterminals werden verklebt.</p>
      </Section>
      <Separator className={styles.separator} />
      <Section title='Preise' subtitle='Transparent und nach Absprache'>
        <p>Preise pro 1 Stück Chassis inkl. Material:</p>
        <table className={styles.prices}>
          <tbody>
            <tr>
              <td>Sickenerneuerung<br /><div className={styles.subtitle}>inkl. farbliche Auffrischung der Membran</div></td>
              <td className={styles.price}><span>35&nbsp;€</span></td>
            </tr>
            <tr>
              <td>Litzenerneuerung, 2&nbsp;Stk.</td>
              <td className={styles.price}><span>15&nbsp;€</span></td>
            </tr>
            <tr>
              <td>Instandsetzung oder Erneuerung Staubkappe</td>
              <td className={styles.price}><span>15&nbsp;€</span></td>
            </tr>
            <tr>
              <td>Montagepauschale 2&nbsp;Stk. BR&nbsp;50 komplett</td>
              <td className={styles.price}><span>20&nbsp;€</span></td>
            </tr>
          </tbody>
        </table>
        <p>Desweiteren können abgerissene Luftspulen der Frequenzweichen neu kontaktiert und aufgelötet werden.</p>
        <table className={styles.prices}>
          <tbody>
            <tr>
              <td>Anschlussabriss innen erneuern</td>
              <td className={styles.price}><span>20&nbsp;€</span></td>
            </tr>
            <tr>
              <td>Anschlussabriss außen erneuern</td>
              <td className={styles.price}><span>15&nbsp;€</span></td>
            </tr>
          </tbody>
        </table>
        <p>Weitere Reparaturen, auch an den Typen L7101, L7104 auf Anfrage.</p>
        <p>Die Rücksendekosten sind vom Auftraggeber zu zahlen.</p>
        <p>Die Lautsprecher können einzeln oder noch in den Boxen montiert angeliefert/zugesandt werden. </p>
      </Section>
      <Separator className={styles.separator} />
      <Section title='Beispiele meiner Reparaturarbeiten'>
        <Carousel className={styles.carousel} autoPlay interval={5000} infiniteLoop statusFormatter={() => ''} renderIndicator={(clickHandler, isSelected) => <div className={(isSelected ? styles.indicatorActive + ' ' : '') + styles.indicator} onClick={clickHandler} />}>
          {
            [
              [example1, 'Sickenreparatur an BR 50, links "Altzustand", rechts repariert.'],
              [example2, 'Originalzustand nach mehr als 20 Jahren, Staubkappe beschädigt.'],
              [example3, '?'],
              [example4, 'Originalzustand nach mehr als 20 Jahren.'],
              [example5, 'Alte Sicken restlos entfernt, Membranen gereinigt.'],
              [example6, 'Membranen nachgefärbt.'],
              [example7, 'Membranen und Staubkappen leicht nachgefärbt, Chassiskörbe nachlackiert, neue Sicken verklebt.'],
              [example8, 'Membranen und Staubkappen leicht nachgefärbt, Chassiskörbe nachlackiert, neue Sicken verklebt.']
            ].map(([image, label]) => <div className={carouselStyles.container}>
              <div className={carouselStyles.labelContainer}>
                <p className={carouselStyles.label}>{label}</p>
              </div>
              <img src={image} alt={label} className={carouselStyles.image} />
            </div>)
          }
        </Carousel>
      </Section>
      <Separator className={styles.separator} />
      <Section title='Kontakt'>
        <p>Für weitere Fragen oder Absprachen stehe ich Ihnen gern per E-Mail oder telefonisch zur Verfügung.</p>
        <div>
          <a className={commonStyles.button + ' ' + styles.button} href='mailto:lautrep@icloud.com'>E-Mail</a>
          <a className={commonStyles.button + ' ' + styles.button} href='tel:+491739926044'>Anruf</a>
        </div>
      </Section>
      <Link to='/impressum'><p className={styles.imprintLink}>Impressum</p></Link>
    </Page>
  );
}
