import { Link } from "react-router-dom";
import Section from "../Section";
import commonStyles from '../Common.module.css';
import Page from "./Page";

export default function NotFoundPage() {
  return (
    <Page>
      <Section title='Seite nicht gefunden'>
        <Link to='/'><div className={commonStyles.button}>Startseite</div></Link>
      </Section>
    </Page>
  );
}
