import styles from './Header.module.css';
import { ReactComponent as Logo } from './res/logo.svg';
import background from './res/speaker-background.jpg';

export default function Header() {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Logo className={styles.logo} />
      </div>
      <div className={styles.overlay} />
      <img src={background} className={styles.background} alt='' />
    </div>
  );
}
