import { Link } from "react-router-dom";
import commonStyles from '../Common.module.css';
import Section from "../Section";
import Page from "./Page";

export default function ImprintPage() {
  return (
    <Page>
      <Section title='Impressum'>
        <p>Heiko Härtwig</p>
        <p>Brunnenweg 3<br />01723 Grumbach</p>
        <p>Tel: <a href='tel:+491739926044'>01739926044</a></p>
        <p>Mail: <a href='mailto:lautrep@icloud.com'>lautrep@icloud.com</a></p>
        <p>Betriebsstättenkennzahl: 14628410 </p>
        <p>AZ: 201900000065</p>
        <Link to='/'><div className={commonStyles.button}>Startseite</div></Link>
      </Section>
    </Page>
  );
}
