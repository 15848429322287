import { Fragment } from "react/cjs/react.production.min";
import Header from "../Header";
import styles from './Page.module.css';

export default function Page({children}) {
  return (
    <Fragment>
      <Header />
      <div className={styles.content}>
        {children}
      </div>
    </Fragment>
  );
}
