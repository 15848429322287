import styles from './Section.module.css';

export default function Section({title, subtitle, children}) {
    return (
        <div className={styles.section}>
            <h2 className={styles.title}>{title}</h2>
            { subtitle && <div className={styles.subtitle}>{subtitle}</div> }
            {children}
        </div>
    );
}
